
import UiButton from "~/components/UI/UiButton";
export default {
  name: "SystemPopup",
  components: { UiButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogState() {
      return this.$store.getters["systemPopup/getDialogState"];
    },
    isConfirm() {
      return this.dialogState.type === "confirm";
    },
  },
  watch: {
    "dialogState.open": {
      handler(val) {
        if (!process.client) return;
        if (val) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    cancel() {
      if (
        this.dialogState.cancelHandler &&
        typeof this.dialogState.cancelHandler === "function"
      ) {
        this.dialogState.cancelHandler();
      }
      this.$nextTick(() => {
        this.closeModal();
      });
    },
    success() {
      if (
        this.dialogState.successHandler &&
        typeof this.dialogState.successHandler === "function"
      ) {
        this.dialogState.successHandler();
      }
      this.$nextTick(() => {
        this.closeModal();
      });
    },
    closeModal() {
      this.$store.dispatch("systemPopup/setDialogState");
    },
    onKeyUp(evt) {
      if (evt.keyCode === 27) {
        if (this.isConfirm) {
          this.cancel();
        } else {
          this.success();
        }
      }
    },
  },
};
